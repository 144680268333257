import { ImageLoaderProps } from 'next/image';

const WORDPRESS_URL =
  process.env.WORDPRESS_API_URL?.replace('/wp-json/wp/v2', '') || 'https://blog.destinations.ai';
const CLOUDINARY_URL = 'https://res.cloudinary.com/dqrovpwjz/image/upload';

const normalizeSrc = (src: string): string => {
  // Remove /public if present
  const withoutPublic = src.replace('/public/', '/');
  // Remove leading slash
  return withoutPublic.startsWith('/') ? withoutPublic.slice(1) : withoutPublic;
};

const isLocalImage = (src: string): boolean => {
  return (
    src.startsWith('/images/') ||
    src.endsWith('.png') ||
    src.endsWith('.jpg') ||
    src.endsWith('.jpeg') ||
    src.endsWith('.gif') ||
    src.endsWith('.webp')
  );
};

export default function imageLoader({ src, width, quality = 75 }: ImageLoaderProps): string {
  // If src is already a full URL, return it as is
  if (src.startsWith('http')) {
    return src;
  }

  // For local images (including PNGs and images in /images/), use Cloudinary
  if (isLocalImage(src)) {
    return `${CLOUDINARY_URL}/f_auto,q_${quality},w_${width}/${normalizeSrc(src)}`;
  }

  // Fallback to WordPress URL
  return `${WORDPRESS_URL}/wp-content/uploads/${normalizeSrc(src)}?w=${width}&q=${quality}`;
}

export function generateOpenGraphImageUrl(imagePath: string): string {
  if (imagePath.startsWith('http')) {
    return imagePath;
  }
  if (isLocalImage(imagePath)) {
    return `${CLOUDINARY_URL}/f_auto,q_auto/${normalizeSrc(imagePath)}`;
  }
  return `${WORDPRESS_URL}/wp-content/uploads/${normalizeSrc(imagePath)}`;
}

export function getCloudinaryUrl(src: string): string {
  return `${CLOUDINARY_URL}/f_auto,q_auto/${normalizeSrc(src)}`;
}
