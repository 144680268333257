'use client'

import { ReactNode } from 'react'

import { Analytics } from '@vercel/analytics/react'
import { SessionProvider } from 'next-auth/react'
import { ErrorBoundary } from 'react-error-boundary'

import ClientErrorFallback from '@/components/ClientErrorFallback'
import { ThemeProvider } from '@/components/theme-provider'
import { Toaster } from '@/components/ui/sonner'
import { AppStateProvider } from '@/lib/utils/app-state'


interface ProvidersProps {
  children: ReactNode
}

export function Providers({ children }: ProvidersProps) {
  return (
    <SessionProvider>
      <ThemeProvider
        attribute="class"
        defaultTheme="system"
        enableSystem
        disableTransitionOnChange
      >
        <AppStateProvider>
          <ErrorBoundary FallbackComponent={ClientErrorFallback}>
            {children}
            <Toaster />
            <Analytics />
          </ErrorBoundary>
        </AppStateProvider>
      </ThemeProvider>
    </SessionProvider>
  )
}
