'use client';

import { useState } from 'react';

import NextImage, { ImageProps } from 'next/image';

import imageLoader, { getCloudinaryUrl } from '@/lib/utils/cloudinaryLoader';

interface WordPressImageProps extends Omit<ImageProps, 'loader'> {
  preload?: boolean;
  alt: string;
  title?: string;
  format?: string;
}

export default function WordPressImage({
  preload: _preload,
  alt,
  title,
  src,
  ...props
}: WordPressImageProps) {
  const [isUsingCloudinary, setIsUsingCloudinary] = useState(false);
  const [imgSrc, setImgSrc] = useState<typeof src>(src);

  const getSourceString = (source: typeof src): string => {
    if (typeof source === 'string') return source;
    if ('src' in source) return source.src;
    return '/images/placeholder-image.jpg';
  };

  return (
    <NextImage
      {...props}
      src={imgSrc}
      alt={alt}
      title={title}
      loader={isUsingCloudinary ? undefined : imageLoader}
      onError={() => {
        if (!isUsingCloudinary) {
          // First failure - try Cloudinary
          setIsUsingCloudinary(true);
          setImgSrc(getCloudinaryUrl(getSourceString(src)));
        } else {
          // If Cloudinary also fails, use placeholder
          console.error('Both WordPress and Cloudinary images failed to load:', src);
          setImgSrc('/images/placeholder-image.jpg');
        }
      }}
    />
  );
}
