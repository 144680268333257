import { LanguageModelV1 } from '@ai-sdk/provider';
import { ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

function formatCurrency(amount: number): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
}

function formatDate(dateString: string): string {
  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  }).format(new Date(dateString));
}

function calculateDuration(startDate: string, endDate: string): number {
  return Math.ceil(
    (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 60 * 60 * 24)
  );
}

function formatDuration(days: number): string {
  if (days === 1) return '1 day';
  return `${days} days`;
}

export function getModel(): LanguageModelV1 {
  const modelName = process.env.OPENAI_MODEL || 'gpt-4-1106-preview';
  return {
    id: modelName,
    name: modelName,
    provider: 'openai',
    modelId: modelName,
    specificationVersion: 'v1',
    defaultObjectGenerationMode: 'json',
    config: {
      model: modelName,
      temperature: 0.7,
      maxTokens: 4096,
    },
    doGenerate: async () => ({ text: '' }),
    doStream: async () => new ReadableStream(),
  } as unknown as LanguageModelV1;
}

export function getBaseUrl() {
  if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}`;
  return `http://localhost:${process.env.PORT || 3000}`;
}

export function sanitizeUrl(url: string) {
  return url.replace(/[^a-zA-Z0-9-_]/g, '-').toLowerCase();
}

interface ToolMessage {
  role: string;
  content: string;
  function_call?: {
    name: string;
    arguments: string;
  };
}

export function transformToolMessages(messages: ToolMessage[]) {
  return messages.map(msg => ({
    role: msg.role,
    content: msg.content,
    function_call: msg.function_call,
  }));
}

export { formatCurrency, formatDate, calculateDuration, formatDuration };
