'use client'

import dynamic from 'next/dynamic'

const HistoryContainer = dynamic(() => import('./history-container'), { 
  ssr: false 
})

export function ClientSidebar() {
  return (
    <div className="h-screen p-2 fixed top-0 right-0 flex-col justify-center pb-24 hidden sm:flex">
      <HistoryContainer location="sidebar" />
    </div>
  )
} 